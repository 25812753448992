import React, { lazy } from 'react'; 
import Login from "./Login";  
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';   
import Dashboard from "./Dashboard";
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import Layout from "./containers/Layout"


function App() {  
  return (  
    <Router>
      <Switch>

        <Route path='/Register' component={Register} />   
        <Route path='/forgot-password' component={ForgotPassword} /> 
          <Route path="/app" component={Layout} />
        <Route path='/' component={Login} />     

      </Switch>
    </Router>     
  );  
}  
  
export default App;

